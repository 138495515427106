<template>
  <div>
    <v-container>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Communal Repairs</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Communal Repairs"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  light
                  v-on="on"
                  :to="{ name: $route.name + '-archived' }"
                >
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>View Archive Communal Repairs</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="deleteApplication.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Application</v-card-title>
        <v-card-text
          >Are you sure you want to archive this application form?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteApplication.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      deleteApplication: {
        dialog: false,
        application: {},
        loading: false,
      },
      groups: [],
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Properties",
          disabled: true,
        },
        {
          text: "All",
          disabled: true,
        },
      ],
    };
  },

  computed: {},

  methods: {
    openDelete(application) {
      this.deleteApplication.application = application;
      this.deleteApplication.dialog = true;
    },

    resetDelete() {
      this.deleteApplication.dialog = false;
      this.deleteApplication.application = {};
      this.deleteApplication.loading = false;
    },

    saveDelete() {
      this.deleteApplication.loading = true;

      this.$store
        .dispatch("sbpm/applicationsStore/deleteApplication", {
          appId: this.$route.params.id,
          applicationId: this.deleteApplication.application,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteApplication.loading = false;
        });
    },
  },
};
</script>
